var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "mb-base no-shadow" },
        [
          _vm.showForm
            ? _c(
                "vs-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.showCapacityForm()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("shops.back")))]
              )
            : _vm._e(),
          _c("transition", { attrs: { name: "fade", moded: "out-in" } }, [
            !_vm.showForm
              ? _c(
                  "div",
                  { staticClass: "mb-base" },
                  [
                    _c("data-table", {
                      attrs: { data: _vm.tableData, options: _vm.tableOptions },
                      on: {
                        create: _vm.showCapacityForm,
                        remove: _vm.onRemove,
                        update: _vm.loadUpdate,
                        sort: _vm.onSort,
                        search: _vm.onSearch,
                        "change-page": _vm.onTablePageChange,
                        "per-page-change": _vm.onPerPageChange
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.showForm
              ? _c(
                  "div",
                  { staticClass: "mb-base mt-10" },
                  [
                    _c("capacity-form", {
                      on: { submit: _vm.submitCapacityForm },
                      model: {
                        value: _vm.dataForForm,
                        callback: function($$v) {
                          _vm.dataForForm = $$v
                        },
                        expression: "dataForForm"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      !_vm.showForm
        ? _c(
            "vx-card",
            { staticClass: "mb-base no-shadow" },
            [
              _vm.capacityTable
                ? _c("capacity-table", { attrs: { value: _vm.capacityTable } })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }