<template>
  <div>
    <vx-card class="mb-base no-shadow">
      <vs-button v-if="showForm" @click="showCapacityForm()">{{
        $t("shops.back")
      }}</vs-button>
      <transition name="fade" moded="out-in">
        <div class="mb-base" v-if="!showForm">
          <data-table
            :data="tableData"
            :options="tableOptions"
            @create="showCapacityForm"
            @remove="onRemove"
            @update="loadUpdate"
            @sort="onSort"
            @search="onSearch"
            @change-page="onTablePageChange"
            @per-page-change="onPerPageChange"
          />
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="mb-base mt-10" v-if="showForm">
          <capacity-form v-model="dataForForm" @submit="submitCapacityForm" />
        </div>
      </transition>
    </vx-card>
    <vx-card class="mb-base no-shadow" v-if="!showForm">
      <capacity-table v-if="capacityTable" :value="capacityTable"></capacity-table>
    </vx-card>
  </div>
</template>

<script>
import DataTable from "@/modules/Shared/Components/table/DataTable";
import CapacityTable from '@/modules/Shops/Components/CapacityTable.vue';
import CapacityForm from "@/modules/Shops/Pages/forms/CapacityForm.vue";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import { mapGetters, mapActions } from "vuex";
import i18n from "@/i18n";

const defaultCapacity = () => ({
  id: 0,
  period_id: 0,
  capacity: 0
});

export default {
  name: "Capacity",

  mixins: [notifications],

  components: {
    DataTable,
    CapacityForm,
    CapacityTable
  },

  data() {
    return {
      showForm: false,
      dataForForm: defaultCapacity()
    };
  },

  computed: {
    ...mapGetters("shops/capacities", {
      capacityList: "getAll",
      totalPages: "getTotalPages",
      currentPage: "getCurrentPage",
      perPage: "getPerPage",
      capacityById: "getById",
      capacityTable: "table"
    }),
    ...mapGetters("shops/periods", { periodList: "getAll" }),

    tableData() {
      return this.capacityList.map(this.capacityToTableData);
    },
    tableOptions() {
      return {
        actions: {
          create: true,
          update: true,
          delete: true,
        },
        pagination: {
          currentPage: this.currentPage,
          totalPages: this.totalPages,
          itemsPerPage: this.perPage,
        },
      }
    }
  },

  methods: {
    ...mapActions("shops/capacities", {
      loadCapacity: "load",
      removeCapacity: "remove",
      updateCapacity: "update",
      createCapacity: "create",
      loadCapacityTable: "getCapacityTable"
    }),
    ...mapActions("shops/periods", { loadPeriodsList: "load" }),

    capacityToTableData(capacity) {
      return {
        id: capacity.id,
        period: this.getPeriodName(capacity.period_id),
        capacity: capacity.capacity,
        created_at: capacity.created_at,
        updated_at: capacity.updated_at
      };
    },

    getPeriodName(periodId){
        let periodName = this.periodList.find(period => period.id == periodId)
        return periodName.name
    },

    async loadCapacityData(params) {
      this.$vs.loading();
      try {
        await this.loadCapacity(params);
        await this.loadPeriodsList({marketing: false});
      } catch (e) {
        console.warn(e);
        this.$vs.loading.close();
      } finally {
        this.$vs.loading.close();
      }
    },

    async showCapacityForm() {
      this.dataForForm = defaultCapacity();
      this.showForm = !this.showForm;
    },

    async submitCapacityForm(capacity) {
      this.$vs.loading();
      try {
        if (capacity.id === 0) {
          delete capacity.id;
          await this.createCapacity(capacity);
          this.notifySuccess(
            i18n.t("shops.pending_to_pay"),
            `${i18n.t('shops.capacity.title')} ${i18n.t('shops.has_been_created_successfully')}`
          );
        } else {
          await this.updateCapacity(capacity);
          this.notifySuccess(
            i18n.t("shops.updated"),
            `${i18n.t('shops.capacity.title')} ${i18n.t('shops.has_been_updated_successfully')}`
          );
        }
        await this.loadCapacityTable();
        this.dataForForm = defaultCapacity();
        this.showForm = false;
      } catch (error) {
        this.notifyError(i18n.t("shops.error.failed_to_save_capacity"), error);
        this.$vs.loading.close();
      } finally {
        this.$vs.loading.close();
      }
    },

    async loadUpdate(capacity) {
      this.showForm = true;
      this.dataForForm = Object.assign(
        {},
        this.dataForForm,
        this.capacityById(capacity.id)
      );
    },

    async onRemove(capacity) {
      this.$vs.loading();
      try {
        await this.$store.dispatch("shops/capacities/remove", capacity);
      } catch (e) {
        console.warn(e);
        this.$vs.loading.close();
      } finally {
        this.notifySuccess(i18n.t("shops.removed"), `${i18n.t("shops.capacity.title")} ${i18n.t('shops.correctly_removed')}`);
        this.$vs.loading.close();
      }
    },

    onSort(payload) {
      let sortOrder = payload.order === "asc" ? "+" : "-";
      this.loadCapacityData({page: this.currentPage, page_size: this.perPage, sort: sortOrder + payload.key});
    },

    onSearch(payload) {
      this.loadCapacityData({page: 1, page_size: this.perPage, q: payload});
    },

    onTablePageChange(page) {
      this.loadCapacityData({ page: page, page_size: this.perPage });
    },

    onPerPageChange(perPage) {
      this.loadCapacityData({ page: this.currentPage, page_size: perPage });
    }
  },

  created() {
    this.$store.commit("shops/capacities/RESET_RESOURCE");
  },
};
</script>

<style></style>
