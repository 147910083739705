var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "capacity-table" },
    [
      _c("data-table", {
        staticClass: "capacityTable",
        attrs: {
          data: _vm.tableData,
          options: _vm.tableOptions,
          exportRequest: _vm.exportRequest
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }