var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "capacity-form" }, [
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c("div", { staticClass: "vx-col" }, [
        _c("div", { staticClass: "w-1/1" }, [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.capacity.title")))
          ]),
          _c(
            "div",
            [
              _c("vs-input-number", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                staticClass: "w-1/1 float-left",
                attrs: {
                  name: "capacity",
                  danger: _vm.errorCapacity && _vm.errors.has("capacity"),
                  "danger-text": _vm.$t("shops.errors.capacity_is_required")
                },
                model: {
                  value: _vm.value.capacity,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "capacity", $$v)
                  },
                  expression: "value.capacity"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "vx-col w-1/4" },
        [
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.period")))
            ]),
            _c("v-select", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full select-auto",
              attrs: {
                options: _vm.periodList.map(function(p) {
                  return { label: p.name, value: p.id }
                }),
                value: _vm.value.period_id,
                name: "period_id",
                danger: _vm.errorPeriod && _vm.errors.has("period"),
                placeholder: _vm.$t("shops.no_periods")
              },
              on: { input: _vm.selectPeriod },
              model: {
                value: _vm.period.name,
                callback: function($$v) {
                  _vm.$set(_vm.period, "name", $$v)
                },
                expression: "period.name"
              }
            })
          ]
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c(
            "vs-button",
            {
              staticClass: "mt-base",
              attrs: { type: "filled" },
              on: {
                click: function($event) {
                  return _vm.validateAndSubmit(_vm.value)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("shops.save")))]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }