<template>
  <div class="capacity-form">
    <div class="vx-row mb-6">
      <div class="vx-col">
        <div class="w-1/1">
          <label class="vs-input--label">{{ $t("shops.capacity.title") }}</label>
          <div>
            <vs-input-number
              class="w-1/1 float-left"
              v-model="value.capacity"
              v-validate="'required'"
              name="capacity"
              :danger="errorCapacity && errors.has('capacity')"
              :danger-text="$t('shops.errors.capacity_is_required')"
            />
          </div>
        </div>
      </div>

      <div class="vx-col w-1/4">
        <template>
          <label class="vs-input--label">{{ $t("shops.period") }}</label>
          <v-select
            v-model="period.name"
            :options="periodList.map((p) => ({label: p.name, value: p.id}))"
            @input="selectPeriod"
            class="w-full select-auto"
            :value="value.period_id"
            name="period_id"
            v-validate="'required'"
            :danger="errorPeriod && errors.has('period')"
            :placeholder="$t('shops.no_periods')"
          />
        </template>
      </div>

      <div class="vx-col w-1/3">
        <vs-button @click="validateAndSubmit(value)" class="mt-base" type="filled">{{ $t("shops.save") }}</vs-button>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import i18n from "@/i18n";

const defaultCapacity = () => ({
  id: 0,
  period_id: 0,
  capacity: 0,
  resort_id: 0
});

export default {
  name: "CapacityForm",

  props: {
    value: { type: Object, default: () => defaultCapacity() },
  },

  mixins: [notifications],

  data() {
    return {
      period: {},
      errorPeriod: false,
      errorCapacity: false
    };
  },

  computed: {
    ...mapGetters("shops/periods", { periodList: "getAll" }),
  },

  methods: {
    validateAndSubmit(value) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("submit", value);
        } else {
          this.notifyWarning(i18n.t("shops.errors.warning"), i18n.t("shops.errors.capacity_validation_failed"));
          if (!this.value.period_id) this.errorPeriod = true;
          if (!this.value.capacity) this.errorCapacity = true;
        }
      });
    },

    selectPeriod(option) {
      this.value.period_id = option === null ? null : option.value;
    }
  },

  created() {
    if(this.value.period_id)
        this.period = this.periodList.find(period => period.id == this.value.period_id)
  }
};
</script>

<style>
</style>
