<template>
    <main class="capacity-table">
        <data-table class="capacityTable" :data="tableData" :options="tableOptions" :exportRequest="exportRequest"/>
    </main>
</template>

<script>
import DataTable from "@/modules/Shared/Components/table/DataTable.vue";

export default {
    name: "capacity-table",

    props: {
        value: { required: true },
    },

    components: {
        DataTable,
    },

    data() {
        return {
            exportRequest: {}
        }
    },

    computed: {
        tableData() {
            return this.value.map(this.capacityToTableData);
        },

        tableOptions() {
            return {
                actions: false,
                filters: false,
                pagination: false,
                export: true
            };
        },
    },

    methods: {
        capacityToTableData(dateCapacity) {
            return {
                date: dateCapacity.date,
                available: dateCapacity.available,
                consumed: dateCapacity.consumed,
                capacity: dateCapacity.capacity
            }
        }
    },

    created() {
        this.exportRequest = { request: `resorts/${this.$route.params.uuid}/capacities_export`, payload: {}};
    }
};
</script>

<style>
    .capacityTable .sort-th {
        display: none;
    }

    .capacityTable th .vs-table-text {
        cursor: default;
    }
</style>
